import { AppState, HomePageBogotaAppState } from '../../../initialStateRegistration';
import {
    HomePageBogotaMinorSections,
    HomePageBogotaSectionsWithLogo,
    SectionNames,
    HomePageBogotaTopSection,
    HomePageBogotaImageLinksSection,
    SectionsWithArticles,
} from './homePageBogota.initialState';

export const getHomePage = (state: AppState) => (state as HomePageBogotaAppState).template;
export const getFeaturedArticle = (key: HomePageBogotaTopSection) => (state: AppState) => getHomePage(state)[key].featuredArticle;
export const getSectionArticlesByKey = (key: SectionsWithArticles) => (state: AppState) => getHomePage(state)[key]?.articles || [];
export const getSectionTitleByKey = (key: SectionNames) => (state: AppState) => getHomePage(state)[key]?.sectionTitle;
export const getSectionLogo = (key: HomePageBogotaSectionsWithLogo) => (state: AppState) => getHomePage(state)[key].sectionTitleLogo;
export const getMoreButtonTextByKey = (key: HomePageBogotaMinorSections | HomePageBogotaImageLinksSection) => (state: AppState) => getHomePage(state)[key]?.moreButtonText;
export const getMoreButtonSvgByKey = (key: HomePageBogotaMinorSections | HomePageBogotaImageLinksSection) => (state: AppState) => getHomePage(state)[key]?.moreButtonSvg;
export const getMetadataDescription = (state: AppState) => getHomePage(state).metadataDescription;
export const getMetadataTitle = (state: AppState) => getHomePage(state).metadataTitle;
export const getMetadataImage = (state: AppState) => getHomePage(state).metadataImage;
export const getHreflangData = (state: AppState) => getHomePage(state).hreflangData;
export const getSectionImageLinks = (key: HomePageBogotaImageLinksSection) => (state: AppState) => getHomePage(state)[key]?.imageLinks || [];
export const getScoreTickerSport = (state: AppState) => getHomePage(state).scoreTickerSport;
