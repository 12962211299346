import React from 'react';
import {
  Ad,
  AppContent,
  baseUnit,
  Carousel,
  ErrorBoundary,
  MEDIA_BREAKPOINTS,
  OneHCThreeVC,
  OneThreeArticleList,
  OneVCNineHLC,
  ScrollableLinkImages,
  SecondarySectionTitle,
  Section,
  SectionHeader,
  SectionWithTitle,
  SPORTRADAR_NAMES,
  SportradarFactory,
  ThreeHCDynamicList,
  tickerCallback,
} from 'mm-ui-components';
import { Store } from 'redux';
import { useSelector } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { Page } from '../Page';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import {
  getHreflangData,
  getMetadataDescription,
  getMetadataImage,
  getMetadataTitle,
  getSectionArticlesByKey,
  getSectionTitleByKey,
  getSectionLogo,
  getMoreButtonSvgByKey,
  getMoreButtonTextByKey,
  getFeaturedArticle,
  getSectionImageLinks,
  getScoreTickerSport,
} from '../../store/template/homePage/homePageBogota/homePageBogota.selectors';
import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import { getAdSlotId, getAdSlotOnSuccess, getCanonicalUrl, getUseFormatMinutesHoursAgo } from '../../store/config/config.selectors';
import {
    SectionsWithArticles,
    HomePageBogotaMinorSections,
    SectionNames,
} from '../../store/template/homePage/homePageBogota/homePageBogota.initialState';
import { createSIMetaImage } from '../../store/template/template.utils';
import { Link } from '../../store/navigations/navigation.utils';


export const FROM_ONE_THREE_ARTICLE_LIST_INDEX = 2;
export const TO_ONE_THREE_ARTICLE_LIST_INDEX = 6;

interface HomePageProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const getArticlesInTemplate = (state: AppState) => {
    const featuredArticle = getFeaturedArticle(SECTION_NAMES.TOP_SECTION)(state) || [];
    const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state);
    const section1Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION1)(state);
    const section2Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION2)(state);
    const section3Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION3)(state);
    const section4Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION4)(state);
    const section5Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION5)(state);
    const section6Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION6)(state);
    const section7Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION7)(state);
    const section8Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION8)(state);
    const section9Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION9)(state);
    const section10Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION10)(state);
    const section11Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION11)(state);
    const section12Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION12)(state);

    return [
        ...featuredArticle,
        ...topSectionArticles,
        ...section1Articles,
        ...section2Articles,
        ...section3Articles,
        ...section4Articles,
        ...section5Articles,
        ...section6Articles,
        ...section7Articles,
        ...section8Articles,
        ...section9Articles,
        ...section10Articles,
        ...section11Articles,
        ...section12Articles,
    ];
};

const OneThreeArticleListWrapper = ({ sectionName, useFormatMinutesHoursAgo = false, createSectionTitle }: { sectionName: SectionsWithArticles; useFormatMinutesHoursAgo?: boolean; createSectionTitle: (sectionTitleSeeMoreButton: Link | null, sectionTitle: string | null | undefined) => string | JSX.Element | null | undefined }) => {
    const sectionArticles = useSelector(getSectionArticlesByKey(sectionName));
    const sectionTitle = useSelector(getSectionTitleByKey(sectionName));
    const sectionMoreButtonText = useSelector(getMoreButtonTextByKey(sectionName as HomePageBogotaMinorSections));
    const sectionMoreButtonSvg = useSelector(getMoreButtonSvgByKey(sectionName as HomePageBogotaMinorSections));

  return sectionArticles.length > 0 ? (
    <SectionWithTitle>
      <SectionHeader moreButtonText={sectionMoreButtonText} moreButtonSvg={sectionMoreButtonSvg}>
        <SecondarySectionTitle>
          {createSectionTitle(sectionMoreButtonText, sectionTitle)}
        </SecondarySectionTitle>
      </SectionHeader>
      <OneThreeArticleList cardsProps={sectionArticles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
    </SectionWithTitle>
  ) : null;
};
export const HomePageBogotaComponent: React.FunctionComponent<HomePageProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <HomePageBogotaContent />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = () => StyleSheet.create({
  sectionsWrapper: {
    [MEDIA_BREAKPOINTS.large]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowGap: `${baseUnit * 2}px`,
      padding: '0 20px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowGap: `${baseUnit}px`,
      padding: '0 10px 0 10px',
    },
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: `${baseUnit * 0.25}px`,
    marginBottom: `${baseUnit * 0.5}px`,
    justifyItems: 'center',
  },
  topWidgetContainer: {
    height: '60px',
    backgroundColor: 'lightblue',
    [MEDIA_BREAKPOINTS.large]: {
      marginBottom: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginBottom: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginBottom: `${baseUnit}px`,
    },
  },
  dividerWrapper: {
    paddingBottom: '10px',
  },
  carouselSection: {
    padding: '0 20px',
  },
  widgetWrapper: {
    boxShadow: 'none',
    marginBottom: '0',
    padding: '0',
  },
  widget: {
    margin: '0',
  },
  tickerWidget: {
    marginBottom: '40px',
    padding: '0',
    minHeight: '60px',
  },
  sectionTitleText: {
    textDecoration: 'inherit',
    color: 'inherit',
  },
});

const paddingFactors = { small: 0, medium: 0, large: 0 };

export const HomePageBogotaContent: React.FunctionComponent = () => {
  const featuredArticle = useSelector(getFeaturedArticle(SECTION_NAMES.TOP_SECTION)) || [];
  const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)) || [];
  const topSectionTitle = useSelector(getSectionTitleByKey(SECTION_NAMES.TOP_SECTION));
  const section1Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION1));
  const section1Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION1));
  const section1MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.SECTION1));
  const section1MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.SECTION1));
  const section7Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION7));
  const section7Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION7));
  const section7MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.SECTION7));
  const section7MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.SECTION7));
  const section8Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION8));
  const section8Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION8));
  const section8MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.SECTION8));
  const section8MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.SECTION8));
  const section9Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION9));
  const section9Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION9)) || '';
  const section9Logo = useSelector(getSectionLogo(SECTION_NAMES.SECTION9));
  const isSVGTitle = section9Logo?.src ? '' : section9Title;
  const section10Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION10));
  const section10Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION10));
  const section10MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.SECTION10));
  const section10MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.SECTION10));
  const section11Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION11));
  const section11Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION11));
  const section11MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.SECTION11));
  const section11MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.SECTION11));
  const section12Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION12));
  const section12Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION12));
  const section12MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.SECTION12));
  const section12MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.SECTION12));
  const issuesSectionTitle = useSelector(getSectionTitleByKey(SECTION_NAMES.ISSUES_SECTION));
  const issuesSectionImageLinks = useSelector(getSectionImageLinks(SECTION_NAMES.ISSUES_SECTION));
  const issuesSectionMoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.ISSUES_SECTION));
  const issuesSectionMoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.ISSUES_SECTION));
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const canonical = useSelector(getCanonicalUrl);
  let pageImage = useSelector(getMetadataImage);
  pageImage = createSIMetaImage(canonical, pageImage);
  const articles = useSelector(getArticlesInTemplate);
  const hreflangData = useSelector(getHreflangData) || [];
  const useFormatMinutesHoursAgo = useSelector(getUseFormatMinutesHoursAgo);
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const belowTopSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowTopSectionAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowSecondSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_SECOND_SECTION));
  const belowSecondSectionAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_SECOND_SECTION));
  const style = createStyle();
  const defaultSport = useSelector(getScoreTickerSport)?.toLowerCase();

  const createSectionTitle = (sectionTitleSeeMoreButton: Link | null, sectionTitle: string | null | undefined) => {
      return sectionTitleSeeMoreButton?.href ? <a className={css(style.sectionTitleText)} href={sectionTitleSeeMoreButton.href}>{sectionTitle}</a> : sectionTitle;
  };

  return (
    <React.Fragment>
      <MetaTags
        articles={articles}
        title={metaTitle}
        description={metaDescription}
        pageImage={pageImage}
        alternateUrls={hreflangData}
      />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent" isFullBleed>
            <SportradarFactory
              name={SPORTRADAR_NAMES.TICKER}
              wrapperStyle={style.tickerWidget}
              widgetStyle={style.widget}
              sportradarProps={{ sport: defaultSport, onItemClick: tickerCallback }}
            />
            <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />
            <div className={css(style.sectionsWrapper)}>
              <Section>
                <OneVCNineHLC
                  cards={[...featuredArticle, ...topSectionArticles]}
                  displayName={topSectionTitle || 'Latest News'}
                  useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                />
              </Section>

              <SectionWithTitle>
                <SectionHeader moreButtonText={section1MoreButtonText} moreButtonSvg={section1MoreButtonSvg}>
                  <SecondarySectionTitle>
                    {createSectionTitle(section1MoreButtonText, section1Title)}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneHCThreeVC
                  cards={section1Articles}
                  useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                />
              </SectionWithTitle>

              {Array.from({ length: TO_ONE_THREE_ARTICLE_LIST_INDEX - FROM_ONE_THREE_ARTICLE_LIST_INDEX + 1 }, (_, i) => {
                const sectionName = `section${i + FROM_ONE_THREE_ARTICLE_LIST_INDEX}` as SectionNames;
                return (
                  <>
                    {
                      i === 1
                      && <Ad id={belowTopSectionAdId} onSuccess={belowTopSectionAdOnSuccess} setMinHeight />
                    }
                    <OneThreeArticleListWrapper
                      key={sectionName}
                      sectionName={sectionName as SectionsWithArticles}
                      useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                      createSectionTitle={createSectionTitle}
                    />
                  </>
                );
              })}
              <Ad id={belowSecondSectionAdId} onSuccess={belowSecondSectionAdOnSuccess} setMinHeight />

              {section7Title && section7Articles.length > 0 && (
                <SectionWithTitle>
                  <SectionHeader moreButtonText={section7MoreButtonText} moreButtonSvg={section7MoreButtonSvg} paddingFactors={paddingFactors}>
                    <SecondarySectionTitle>
                      {createSectionTitle(section7MoreButtonText, section7Title)}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <Carousel itemType="VerticalCard" itemsProps={section7Articles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>
                )}

              <Section>
                <SportradarFactory
                  name={SPORTRADAR_NAMES.GOLF_LEADERBOARD}
                  wrapperStyle={style.widgetWrapper}
                  widgetStyle={style.widget}
                />
              </Section>

              {section8Title && section8Articles.length > 0 && (
                <SectionWithTitle>
                  <SectionHeader moreButtonText={section8MoreButtonText} moreButtonSvg={section8MoreButtonSvg} paddingFactors={paddingFactors}>
                    <SecondarySectionTitle>
                      {createSectionTitle(section8MoreButtonText, section8Title)}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <Carousel itemType="VerticalCard" itemsProps={section8Articles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>
                )}

              <Section>
                <ThreeHCDynamicList
                  cards={section9Articles}
                  title={isSVGTitle}
                  image={section9Logo}
                />
              </Section>

              {section10Title && section10Articles.length > 0 && (
                <SectionWithTitle>
                  <SectionHeader moreButtonText={section10MoreButtonText} moreButtonSvg={section10MoreButtonSvg} paddingFactors={paddingFactors}>
                    <SecondarySectionTitle>
                      {createSectionTitle(section10MoreButtonText, section10Title)}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <Carousel itemType="VerticalCard" itemsProps={section10Articles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>
                )}

              {section11Title && section11Articles.length > 0 && (
                <SectionWithTitle>
                  <SectionHeader moreButtonText={section11MoreButtonText} moreButtonSvg={section11MoreButtonSvg} paddingFactors={paddingFactors}>
                    <SecondarySectionTitle>
                      {createSectionTitle(section11MoreButtonText, section11Title)}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <Carousel itemType="VerticalCard" itemsProps={section11Articles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>
                )}

              {section12Title && section12Articles.length > 0 && (
              <SectionWithTitle>
                <SectionHeader moreButtonText={section12MoreButtonText} moreButtonSvg={section12MoreButtonSvg} paddingFactors={paddingFactors}>
                  <SecondarySectionTitle>
                    {createSectionTitle(section12MoreButtonText, section12Title)}
                  </SecondarySectionTitle>
                </SectionHeader>
                <Carousel itemType="VerticalCard" itemsProps={section12Articles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
              </SectionWithTitle>
              )}

              <SectionWithTitle>
                <SectionHeader moreButtonText={issuesSectionMoreButtonText} moreButtonSvg={issuesSectionMoreButtonSvg} paddingFactors={paddingFactors}>
                  <SecondarySectionTitle>
                    {createSectionTitle(issuesSectionMoreButtonText, issuesSectionTitle)}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ScrollableLinkImages
                  linkImages={issuesSectionImageLinks}
                  customRatios={{ large: { x: 3, y: 4 }, medium: { x: 3, y: 4 }, small: { x: 3, y: 4 } }}
                  paddingFactors={paddingFactors}
                />
              </SectionWithTitle>

            </div>
          </NavigationLayout>
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};
